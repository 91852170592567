import {Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import {useUserAgentContext} from '@/context';

interface ICoverageHeaderProps {
  drugName: string;
}

const CoverageHeader: FC<ICoverageHeaderProps> = ({drugName}) => {
  const {isMobileOrTablet} = useUserAgentContext();

  if (isMobileOrTablet) {
    return (
      <div className='flex flex-col space-y-3 md:space-y-6'>
        <Text as='headline-sm' weight='bold'>
          {`Get Your Patient on ${drugName}`}
        </Text>
        <Text as='body-md' size='md'>
          See your patient&apos;s specific prior authorization requirements
          including coverage restrictions and step therapies
        </Text>
      </div>
    );
  }

  return (
    <div className='flex flex-col space-y-3 md:space-y-6 md:text-center'>
      <Text as='headline-lg' weight='bold'>
        Check Coverage Restrictions
      </Text>
    </div>
  );
};

export default CoverageHeader;
