import {Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC} from 'react';

interface IStepTherapyStepProps {
  dependenciesCount: number;
  requirements: string[];
  isLastStep: boolean;
}

const StepTherapyStep: FC<IStepTherapyStepProps> = ({
  dependenciesCount,
  requirements,
  isLastStep,
}) => (
  <div
    data-testid='step-therapy-card'
    className='flex w-full flex-row items-center space-x-3'>
    <div
      className={clsx(
        "self-stretch after:mx-2 after:block after:h-full after:w-[1px] after:bg-neutral-quaternary after:content-['']",
        {
          'after:hidden': isLastStep,
        },
      )}>
      <div className='flex items-center justify-center rounded-full bg-neutral-light p-[5px]'>
        <div className='h-2 w-2 rounded-full bg-white' />
      </div>
    </div>
    <div
      className={clsx('flex flex-col flex-wrap', {
        'mb-10': !isLastStep,
      })}>
      <Text weight='bold' size='sm'>
        {`${dependenciesCount} of this options`}
      </Text>
      <Text
        size='sm'
        weight='semibold'
        casing='title'
        className='text-neutral-secondary'>
        {requirements.join(', ')}
      </Text>
    </div>
  </div>
);

export default StepTherapyStep;
